<template>
  <div>
    <div
      class="avatar-block"
    >
      <el-upload
        ref="upload"
        class="avatar-uploader el-upload"
        :class="{circle: circle}"
        action="#"
        list-type="picture-card"
        :auto-upload="false"
        :on-change="onChange"
        :file-list="fileList"
      >
        <i
          slot="default"
          class="el-icon-plus "
        />
        <div
          slot="file"
          slot-scope="{ file }"
        >
          <img
            :src="file.url"
            class="avatar"
            alt="logo"
          >
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-edit"
            >
              <i
                class="el-icon-edit"
                @click="handleEdit"
              />
            </span>
            <span
              class="el-upload-list__item-delete"
            >
              <i
                class="el-icon-delete"
                @click="handleRemove"
              />
            </span>
          </span>
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UploadPicture',
  props: {
    circle: {
      type: Boolean,
    },
    fieldFromState: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fileList: [{ name: 'logo', url: this.fieldFromState.logo }],
    };
  },
  computed: {
    checkImgList() {
      return document.querySelector('div.avatar-uploader ul.el-upload-list--picture-card');
    },
  },

  mounted() {
    this.hidePlus();
  },

  methods: {
    ...mapActions([
      'removeLogoUrl',
    ]),
    handleEdit() {
      this.$refs.upload.$refs['upload-inner'].handleClick();
      this.checkImg();
    },
    handleRemove() {
      this.$refs.upload.uploadFiles = [];
      this.$emit('logoFile', this.fieldFromState.logo);
      this.removeLogoUrl(this.fieldFromState);
      this.showPlus();
    },
    onChange(file) {
      this.hidePlus();
      this.$emit('logoFile', file);
      this.checkImg();
    },
    checkImg() {
      if (typeof this.checkImgList.children[0] !== 'undefined') {
        const { parentElement } = this.checkImgList.children[0];

        setTimeout(() => {
          if (parentElement.children[1]) {
            parentElement.children[0].remove();
          }
        }, 1);
      }

      return false;
    },
    hidePlus() {
      const addLogoBlock = document.querySelector('div.el-upload--picture-card');

      addLogoBlock.style.display = 'none';

      return false;
    },
    showPlus() {
      const addLogoBlock = document.querySelector('div.el-upload--picture-card');

      setTimeout(() => {
        addLogoBlock.style.display = 'block';
      }, 1001);

      return false;
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>
  .avatar-uploader, .el-upload {
    border        : 1px dashed #d9d9d9;
    border-radius : 6px;
    cursor        : pointer;
    position      : relative;
    overflow      : hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color : #409EFF;
  }

  .el-upload:hover {
    border-color : #409EFF;
  }

  .el-icon-edit,
  .el-icon-delete {
    font-size   : 28px;
    transition  : .1s all;
  }

  .el-icon-edit:hover,
  .el-icon-delete:hover {
    border-radius: 5px;
    padding: 5px;
    border  : 1px solid #fff;
  }

  .el-icon-plus {
    margin-top: 50%;
    transform: translateY(-50%);
  }

  .avatar-uploader-icon {
    font-size   : 28px;
    color       : #8c939d;
    width       : 178px;
    height      : 120px;
    line-height : 120px;
    text-align  : center;
  }

  .avatar {
    max-height  : 150px;
    display     : block;
    position    : absolute;
    top         : 50%;
    left        : 50%;
    transform   : translate(-50%, -50%);
  }

  .circle {
    border-radius : 50%;
  }

  .avatar-uploader {
    & >>> .el-upload-list__item {
      position      : relative;
      width         : 150px;
      height        : 150px;
      text-align    : center;
      margin        : 0 0 -5px 0;
      border: none;
    }
    & >>> .el-upload--picture-card {
      border        : none;
    }
    & >>> :focus {
      outline       : none;
    }
  }
</style>
